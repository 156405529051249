import { NextRequest, NextResponse } from 'next/server';

import { getUserInfoFromCookies } from './read-cookies';
import { Roles, UserInfo } from './user-info';

export function permissionsMiddleware(request: NextRequest): NextResponse<unknown> | null {
  try {
    const [, user] = getUserInfoFromCookies(request.cookies);
    if (!user) {
      return null;
    }

    // Check if the user has access to specific products
    const hasAccessToProducts = checkEntitlementsAccess({ user, request });
    if (hasAccessToProducts) {
      return hasAccessToProducts;
    }

    return null;
  } catch (err) {
    console.error(err, 'middleware crashed');
    return NextResponse.redirect(new URL('/auth/sign-out', request.url));
  }
}

function checkEntitlementsAccess({ user, request }: { user: UserInfo; request: NextRequest }) {
  const response404 = NextResponse.redirect(new URL('/404', request.url));

  if (
    getSectionRegExp('compliance').test(request.nextUrl.pathname) &&
    !user.permissions['network-compliance'].canAccess
  ) {
    return response404;
  } else if (getSectionRegExp('vaults').test(request.nextUrl.pathname) && !user.permissions.vaults.canAccess) {
    return response404;
  } else if (getSectionRegExp('reports').test(request.nextUrl.pathname) && !user.permissions.reports.canAccess) {
    return response404;
  } else if (getSectionRegExp('dapps').test(request.nextUrl.pathname) && !user.permissions.dapps.canAccess) {
    return response404;
  } else if (
    getSectionRegExp('admin/settings/billing').test(request.nextUrl.pathname) &&
    !user.permissions.billing.canAccess
  ) {
    return response404;
  } else if (
    getSectionRegExp('admin/settings').test(request.nextUrl.pathname) &&
    !(user.role === Roles.Admin || user.role === Roles.AdminCompliance)
  ) {
    return response404;
  }
}

function getSectionRegExp(
  section:
    | 'getting-started'
    | 'compliance'
    | 'vaults'
    | 'reports'
    | 'dapps'
    | 'admin/settings'
    | 'admin/settings/billing',
) {
  return new RegExp(`^(\\/[a-z]{2})?\\/${section}`);
}
